import {Chat, DeleteIcon, TertiaryButton, message, theme, useToggle} from '@settleindex/react2'
import type {WsRoutes} from '@settleindex/ws/src'
import type {ChatMessageType} from '@settleindex/ws/src/help/ChatSchema.js'
import {hc} from 'hono/client'
import {useCallback, useMemo} from 'react'
import useLocalStorage from 'use-local-storage'
import {captureException} from '#lib/captureException.js'
import {secondaryNavHeight} from '#lib/navigation/secondary/secondaryNavHeight.js'
import {useConfig} from '#lib/useConfig/useConfig.js'
import {useUser} from '#lib/user/useUser/useUser.js'

interface HelpChatProps {}

export const HelpChat: React.FC<HelpChatProps> = () => {
  const isLoading = useToggle(false)
  const [collapsed, setCollapsed] = useLocalStorage<boolean>('helpChatCollapsed', true)
  const initialMessages: ChatMessageType[] = useMemo(
    () => [
      {
        date: '1111-11-11T11:11:11Z',
        kind: 'answer',
        message: `Hello! I'm Bernoulli, and my job is to help you understand everything about SettleIndex.
    I'm here for training and familiarization purposes.
    For live cases, it's important to rely on human support for checking models and advice.

    Please note: this is an AI assistant, responses are not always accurate. Your personal or case data is never sent.`,
      },
    ],
    [],
  )
  const [messages, setMessages] = useLocalStorage<ChatMessageType[]>('helpChatMessages', initialMessages)
  const {wsUrl} = useConfig()
  const {getToken} = useUser()

  const sendChat = useCallback(
    (newMessages: any) => {
      isLoading.setTrue()
      // @ts-ignore
      const client = hc<WsRoutes>(wsUrl)

      // @ts-ignore hono types are not very good unfortunately
      client.help.chat
        .$post(
          {
            json: {
              messages: newMessages,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          },
        )
        .then(async (res: Response) => {
          if (res.ok) {
            const data = await res.json()
            setMessages(data.messages)
          } else {
            message.error(`Couldn't send chat message`)
            console.error('error in help/chat:', res)
            captureException(new Error(`Error in HelpChat: ${JSON.stringify(res)}`))
          }
        })
        .catch((e) => {
          message.error(`Couldn't send chat message`)
          console.error('error in help/chat:', e)
          captureException(new Error(e))
        })
        .finally(isLoading.setFalse)
    },
    [wsUrl, getToken, isLoading, setMessages],
  )

  const onSubmit = useCallback(
    (messages: any) => {
      setMessages(messages)
      sendChat(messages)
    },
    [setMessages, sendChat],
  )

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        position: 'fixed',
        bottom: 0,
        right: 20,
        zIndex: 1000,
        height: collapsed ? 45 : `calc(100vh - ${secondaryNavHeight}px)`,
      }}
    >
      <Chat
        placeholder={'Ask Bernoulli a question and press enter'}
        onCollapseChange={setCollapsed}
        collapsed={collapsed}
        loading={isLoading.value}
        messages={messages}
        onSubmit={onSubmit}
        footer={
          messages.length > 1 && (
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <TertiaryButton
                size="small"
                icon={<DeleteIcon size={16} />}
                onClick={() => setMessages(initialMessages)}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  color: theme.colors.primary.textOnNeutral,
                }}
              >
                Clear chat
              </TertiaryButton>
            </div>
          )
        }
      />
    </div>
  )
}
